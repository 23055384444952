<template>
	<div id="adoptOrderList">
		<page-order-list :is-shop="1"></page-order-list>
	</div>
</template>

<script>
	import pageOrderList from '@/components/layout/adopt/page-order-list.vue'
	export default{
		components:{
			pageOrderList
		},
		beforeRouteLeave (to, from, next) {
		    from.meta.keepAlive = false;
		    next();
		},
	}
</script>

<style>

</style>
